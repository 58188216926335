const config = {
  nav: [
    { href: "/", title: "Home" },
    { href: "/blog", title: "Blog" },
    { href: "/mentoring", title: "Mentoring" },
    { href: "/photography", title: "Photography" },
    { href: "/about", title: "About" },
  ],
  author: {
    name: "Luca Cavallin",
    avatar: "/images/luca_cavallin.webp",
    avatar_base64:
      "data:image/webp;base64,UklGRmwAAABXRUJQVlA4IGAAAABQAgCdASoKAAoAAgA0JZQCw7aXO+hhH+QBBOAA/vKTzbbUpr/eHmpz8ekbafkPj7Ef65+dCpZ//XE/qW28o2FRVHdy/aVLjXqNTVEgM9dkDl7PKxpA/mWAruWlxSQYAAA=",
    job: "Software Engineer, Mentor, Photographer.",
    company: "GitHub, Inc.",
    github: "https://github.com/lucavallin",
    linkedin: "https://www.linkedin.com/in/lucavallin",
    unsplash: "https://unsplash.com/@lucavallin",
    instagram: "https://instagram.com/lucavallincom",
    pinterest: "https://pinterest.com/lucavallincom",
    photography: "https://www.lucavallin.com",
    mentorcruise: "https://mentorcruise.com/mentor/lucacavallin/",
    pixels: "https://pixels.com/profiles/luca-cavallin",
  },
  meta: {
    title: "Luca Cavallin - Software Engineering, Mentoring & Photography",
    title_short: "Luca Cavallin",
    headerTitle: "lucavallin",
    description: `
      Luca Cavallin, Software Engineer at GitHub. I am passionate about developer and infrastructure tools, with expertise in Go, Kubernetes, and the Cloud Native domain. Currently, I am expanding my knowledge in Rust, systems- and network-programming. I'm an active
      contributor to open source and I try to share whatever knowledge I have. Additionally, I hold the title of Google Developer Expert (GDE) and CNCF Ambassador. Outside of work, activities like photography, cycling, cooking on a kamado and cleaning litterboxes keep me occupied.`,
    photography_description:
      "I've been passionate about photography since I was a kid. I enjoy capturing a variety of subjects—landscapes, wildlife, macro shots, and sometimes industrial scenes. Whether it's the early morning light over a misty mountain, the tiny details of an insect, or the gritty look of factory chimneys, photography lets me explore and share how I see the world.",
    mentoring_description:
      "Looking to level up your software engineering career? With personalized mentorship on Mentorcruise, I'll help you crush your career goals, tackle software challenges, and achieve the success you're aiming for. Let's make your goals happen together!",
    blog_description:
      "Check out my blog for insights on Cloud Native tech, Go, Kubernetes, and my other tech passions. As a Senior Software Engineer at GitHub Actions and a MentorCruise mentor, I share tips on developer tools, career advice, and open source. Discover practical advice, tech stories, and hands-on support!",
    language: "en-us",
    theme: "system", // system, dark or light
    siteUrl: "https://www.lucavall.in",
    siteLogo: "/images/lucavallin.webp",
    socialBanner: "/images/meta.webp",
    locale: "en-US",
    search: {
      provider: "kbar",
      kbarConfig: {
        searchDocumentsPath: "search.json",
      },
    },
  },
};

export default config;
