"use client";

import config from "@/data/config.mts";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePathname } from "next/navigation";
import { useState } from "react";
import CustomLink from "./CustomLink";

const MobileNav = () => {
  const [navShow, setNavShow] = useState(false);
  const pathname = usePathname();

  const onToggleNav = () => {
    setNavShow((status) => {
      if (status) {
        document.body.style.overflow = "auto";
      } else {
        // Prevent scrolling
        document.body.style.overflow = "hidden";
      }
      return !status;
    });
  };

  return (
    <>
      <button aria-label="Toggle Menu" onClick={onToggleNav} className="sm:hidden">
        <FontAwesomeIcon icon={faBars} size="xl" className="text-gray-900" />
      </button>
      <div
        className={`fixed left-0 top-0 z-10 size-full bg-gray-950 opacity-[0.98] duration-300 ease-in-out ${
          navShow ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex justify-end">
          <button className="mr-8 mt-11 size-8" aria-label="Toggle Menu" onClick={onToggleNav}>
            <FontAwesomeIcon icon={faTimes} size="2xl" className="text-gray-900" />
          </button>
        </div>
        <nav className="fixed mt-8 h-full">
          {config.nav.map((link) => (
            <div key={link.title} className="px-12 py-4">
              <CustomLink
                href={link.href}
                title={link.title}
                aria-label={link.title}
                className={`text-2xl tracking-widest ${(pathname == "/" && link.href == "/") || (link.href !== "/" && pathname.includes(link.href)) ? "font-semibold text-pink" : "font-medium text-gray-900"}`}
                onClick={onToggleNav}
              >
                {link.title}
              </CustomLink>
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default MobileNav;
