/* eslint-disable jsx-a11y/anchor-has-content */
import type { LinkProps } from "next/link";
import Link from "next/link";
import { AnchorHTMLAttributes } from "react";

const CustomLink = ({ href, ...rest }: LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const isExternalLink = href && !(href.startsWith("#") || href.startsWith("/"));

  if (isExternalLink) {
    return <Link target="_blank" rel="noopener noreferrer" href={href} {...rest} />;
  }

  return <Link href={href} {...rest} />;
};

export default CustomLink;
