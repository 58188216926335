"use client";

import config from "@/data/config.mts";
import Image from "next/image";
import { usePathname } from "next/navigation";
import CustomLink from "./CustomLink";
import MobileNav from "./MobileNav";
import SearchButton from "./SearchButton";

const Header = () => {
  const pathname = usePathname();

  return (
    <header className="flex items-center justify-between py-10">
      <div>
        <CustomLink href="/" title={config.meta.headerTitle} aria-label={config.meta.headerTitle}>
          <div className="flex items-center justify-between">
            <div className="mr-3">
              <Image
                src={"/images/logo.webp"}
                alt={config.meta.headerTitle}
                width={220}
                height={55}
              />
            </div>
            <div className="hidden h-6 text-2xl font-semibold">{config.meta.headerTitle}</div>
          </div>
        </CustomLink>
      </div>
      <div className="flex items-center space-x-4 leading-5 sm:space-x-6">
        {config.nav.map((link) => (
          <CustomLink
            key={link.title}
            href={link.href}
            className={`hidden sm:block ${(pathname == "/" && link.href == "/") || (link.href !== "/" && pathname.includes(link.href)) ? "font-semibold text-pink" : "font-medium text-gray-900"}`}
            title={link.title}
            aria-label={link.title}
          >
            {link.title}
          </CustomLink>
        ))}
        <SearchButton />
        <MobileNav />
      </div>
    </header>
  );
};

export default Header;
